import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { authAxios } from "../config/config";
import IsLoadingHOC from "../Common/IsLoadingHOC";
import { trimObjValues } from "../Helper";
import { maxUserPhoneLength, minUserPhoneLength } from "../Constants";

// first_name, last_name, company_name, email, phone, message, feedback

interface MyComponentProps {
  setLoading: (isComponentLoading: boolean) => void;
  isLoading: boolean;
}

function PrivacyPolicy(props: MyComponentProps) {
  const { setLoading, isLoading } = props;
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    phone: "",
    message: "",
    feedback: "",
  });

  const divStyle = {
    'padding': '10px',

  };

  const handleModalClose = () => {
    setShowSuccessPopup(false);
  };

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleRedirect = () => {
    window.location.href = '/';
  };

  return (
    <>
      <section className="default--top--banner">
        <div className="banner--thumbnuil">
          <img
            src={require("../asstes/image/about--banner.jpg")}
            alt="PrivacyPolicy"
            loading="lazy"
          />
        </div>
        <div
          className="banner--heading"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h1>Privacy Policy</h1>
        </div>
      </section>

      <section className="">
            <div className="grid">
              <div className="grid----">
                <div className="grid--wrapper">
                  <div className="about--us--wrapper">
                    <div className="left--sq--box"></div>
                    <div className="about--us--content">
                      <div className="title--heading">
                        <h3>
                        Effective Date: March 10, 2025
                        </h3>
                        {/* <h2>
                          Terms of Service
                        </h2> */}
                      </div>

                      <div style={divStyle}>
                        <section>
                          <p>Thank you for visiting Bold Portable. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, disclose, and protect your information when you visit our website, use our mobile app, or engage with our services, including but not limited to booking, maintenance requests, and leaving reviews. By using our services, you agree to the practices outlined in this Privacy Policy.</p>
                        </section>

                        <br></br>
                        <section>
                          <h3>1. Information We Collect</h3>
                          <p>We collect information in the following ways:</p>
                          <ul>
                            <li><p><strong>Personal Information:</strong> When you visit our website or use our mobile app, we may collect personal details such as your name, email address, phone number, and billing information. This information is collected when you create an account, make a booking, request a service, or leave a review.</p></li>
                            <li><p><strong>Device and Usage Data:</strong> We may collect information about your device (e.g., IP address, browser type, operating system) and how you interact with our website or app (e.g., usage data, pages visited, time spent on pages).</p></li>
                            <li><p><strong>Location Data:</strong> If you use our app, we may collect location data (such as your GPS coordinates) to provide you with relevant services based on your location. You can disable location tracking through your device settings.</p></li>
                            <li><p><strong>Payment Information:</strong> We may collect payment-related information (such as credit card details) when you make a booking. This information is processed by third-party payment providers and is not stored on our servers.</p></li>
                          </ul>
                        </section>

                        <br></br>
                        <section>
                          <h3>2. How We Use Your Information</h3>
                          <p>We use the information we collect for the following purposes:</p>
                          <ul>
                            <li><p><strong>To Provide Services:</strong> To process and manage your bookings, maintenance requests, and service inquiries. This includes confirming your bookings and sending notifications related to your service.</p></li>
                            <li><p><strong>To Improve Our Services:</strong> To enhance your experience by analyzing usage patterns, improving the functionality of our website and app, and developing new features.</p></li>
                            <li><p><strong>To Communicate with You:</strong> To respond to your inquiries, provide customer support, and send you important information about your account, bookings, or service updates.</p></li>
                            <li><p><strong>For Marketing and Promotions:</strong> We may send you promotional emails or messages about our services. You can opt out of receiving marketing communications at any time by following the unsubscribe instructions in our communications.</p></li>
                            <li><p><strong>To Process Payments:</strong> To facilitate secure payment processing and billing for the services we provide.</p></li>
                            <li><p><strong>For Legal Compliance:</strong> To comply with applicable laws and regulations, and to protect our rights, property, or safety, as well as the rights, property, and safety of our customers.</p></li>
                          </ul>
                        </section>

                        <br></br>
                        <section>
                          <h3>3. Sharing Your Information</h3>
                          <p>We may share your personal information in the following circumstances:</p>
                          <ul>
                            <li><p><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our business, such as payment processors, hosting providers, and customer support services. These providers are only allowed to use your information to perform services on our behalf.</p></li>
                            <li><p><strong>Legal Compliance:</strong> We may disclose your information when required by law or in response to legal requests, such as subpoenas, court orders, or government investigations.</p></li>
                            <li><p><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or part of our business, your personal information may be transferred as part of the transaction.</p></li>
                          </ul>
                        </section>

                        <br></br>
                        <section>
                          <h3>4. Data Security</h3>
                          <p>We take reasonable precautions to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
                        </section>

                        <br></br>
                        <section>
                          <h3>5. Your Rights and Choices</h3>
                          <ul>
                            <li><p><strong>Access and Update Your Information:</strong> You can access, update, or correct your personal information by logging into your account or contacting us directly.</p></li>
                            <li><p><strong>Opt-Out of Marketing Communications:</strong> You can opt out of receiving marketing emails at any time by clicking the "unsubscribe" link in the emails or by contacting us directly.</p></li>
                            <li><p><strong>Delete Your Account:</strong> If you wish to delete your account or request that we no longer use your personal information for certain purposes, you can contact us directly.</p></li>
                          </ul>
                        </section>

                        <br></br>
                        <section>
                          <h3>6. Consumer Consent and Data Sharing</h3>
                          <p>We do not share your mobile information with third parties for marketing purposes. Your data is only used to provide and improve our services, and we ensure that your consent is obtained before any data sharing occurs.</p>
                        </section>

                        <br></br>
                        <section>
                          <h3>7. Data Usage and Sharing</h3>
                          <p>We collect your phone number to send you SMS notifications. Your data will not be shared with third parties. For any inquiries, please contact us at support@example.com.</p>
                        </section>

                        <br></br>
                        <section>
                          <h3>8. Data Transfer and Sharing</h3>
                          <p>We do not transfer your personal data to third parties without your consent, and we have implemented strict measures to prevent unauthorized access or sharing of your information.</p>
                        </section>

                        <br></br>
                        <section>
                          <h3>9. Cookies and Tracking Technologies</h3>
                          <p>We use cookies and similar tracking technologies to enhance your experience on our website and app. Cookies are small files stored on your device that help us remember your preferences and understand how you use our services. You can control the use of cookies through your browser settings, but disabling cookies may impact your experience on our website or app.</p>
                        </section>

                        <br></br>
                        <section>
                          <h3>10. Third-Party Links</h3>
                          <p>Our website or app may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review their privacy policies before providing any personal information.</p>
                        </section>

                        <br></br>
                        <section>
                          <h3>11. Children's Privacy</h3>
                          <p>Our services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children. If we learn that we have collected personal information from a child under the age of 13, we will take steps to delete such information as soon as possible.</p>
                        </section>

                        <br></br>
                        <section>
                          <h3>12. International Data Transfers</h3>
                          <p>If you are accessing our website or app from outside the United States, please note that your personal information may be transferred to and processed in the United States, where our servers are located. By using our services, you consent to this transfer.</p>
                        </section>

                        <br></br>
                        <section>
                          <h3>13. Changes to This Privacy Policy</h3>
                          <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. When we make changes, we will update the "Effective Date" at the top of this policy. We encourage you to review this policy periodically to stay informed about how we are protecting your information.</p>
                        </section>

                        <br></br>
                        <section>
                          <h3>14. Data Sharing Practices</h3>
                          <p>We do not share your mobile information with third parties for marketing purposes without your explicit consent. Data sharing is limited to purposes directly related to our business operations.</p>
                        </section>

                        <br></br>
                        <section>
                          <h3>15. Consumer Data Usage</h3>
                          <p>We collect your phone number to send you SMS notifications. Consumers have the right to opt out of the messaging campaign at any time. Your data will not be shared with third parties. For any inquiries, please contact us at info@go-bold.ca </p>
                        </section>

                        <br></br>
                        <section>
                          <h3>16. Opt-Out Rights</h3>
                          <p>You have the right to opt out of our messaging campaign at any time. To opt out, reply with STOP to any of our messages or contact our support team for assistance.</p>
                        </section>

                        <br></br>
                        <section>
                          <h3>17. Contact Us</h3>
                          <p>If you have any questions or concerns about this Privacy Policy, or if you wish to exercise your rights regarding your personal information, please contact us at:</p>
                        <section/>

                          <br></br>
                          <p><strong>Bold Portables</strong></p>
                          <p>info@go-bold.ca</p>
                          <p>+1 (259) 869-5444</p>
                        </section>
                      </div>

      
                      <div className="line--1"></div>
                    </div>
                    <div className="right--sq--box"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>

      {showSuccessPopup && (
        <div id="success--popup" className="success--container">
          <span id="close--modal" onClick={handleModalClose}>x</span>
          <div className="success--content">
            <p>Your request has been submited. One of our team members will be contacting you shortly.</p>
            <button onClick={handleRedirect} className="btn">Go to Home Page</button>
          </div>
        </div>
      )}

    </>
  );
}

export default IsLoadingHOC(PrivacyPolicy);
