import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { authAxios } from "../config/config";
import IsLoadingHOC from "../Common/IsLoadingHOC";
import { trimObjValues } from "../Helper";
import { maxUserPhoneLength, minUserPhoneLength } from "../Constants";

// first_name, last_name, company_name, email, phone, message, feedback

interface MyComponentProps {
  setLoading: (isComponentLoading: boolean) => void;
  isLoading: boolean;
}

function TermsOfService(props: MyComponentProps) {
  const { setLoading, isLoading } = props;
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    phone: "",
    message: "",
    feedback: "",
  });

  const divStyle = {
    'padding': '10px',

  };

  const handleModalClose = () => {
    setShowSuccessPopup(false);
  };

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleRedirect = () => {
    window.location.href = '/';
  };

  return (
    <>
      <section className="default--top--banner">
        <div className="banner--thumbnuil">
          <img
            src={require("../asstes/image/about--banner.jpg")}
            alt="Terms of Service"
            loading="lazy"
          />
        </div>
        <div
          className="banner--heading"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h1>Terms of Service</h1>
        </div>
      </section>

      <section className="">
            <div className="grid">
              <div className="grid----">
                <div className="grid--wrapper">
                  <div className="about--us--wrapper">
                    <div className="left--sq--box"></div>
                    <div className="about--us--content">
                      <div className="title--heading">
                        <h3>
                        Effective Date: March 10, 2025
                        </h3>
                        {/* <h2>
                          Terms of Service
                        </h2> */}
                      </div>

                      <div style={divStyle}>
                      <br></br>
                        <section>
                        <h3>1. Types of Messages</h3>
                        <p>We may send the following types of messages to users:</p>
                        <ul>
                            <li><p><strong>Promotional:</strong> Messages related to offers, discounts, and special deals.</p></li>
                            <li><p><strong>Informational:</strong> Messages providing updates or important information about our services.</p></li>
                            <li><p><strong>Marketing Content:</strong> Messages that contain marketing materials or advertisements from us or our partners.</p></li>
                        </ul>
                        </section>

                        <br></br>
                        <section>
                        <h3>2. Message Frequency</h3>
                        <p>Users can expect to receive messages from us on a weekly basis. Frequency may vary depending on the nature of the message.</p>
                        </section>

                        <br></br>
                        <section>
                        <h3>3. Message Rates</h3>
                        <p><strong>Note:</strong> Message and data rates may apply. Please check with your mobile carrier for any charges that may apply to receiving messages from us.</p>
                        </section>

                        <br></br>
                        <section>
                        <h3>4. Sender Identification</h3>
                        <p>These terms and conditions are provided by <strong>Bold Portables</strong>. You can contact us for any inquiries or concerns related to these terms.</p>
                        </section>

                        <br></br>
                        <section>
                        <h3>5. Unsubscribe and Assistance</h3>
                        <p>If you no longer wish to receive messages from us, you can easily unsubscribe by following the instructions below:</p>
                        <ul>
                            <li><p><strong>Unsubscribe:</strong> Text <strong>STOP</strong> to unsubscribe from future messages.</p></li>
                            <li><p><strong>Assistance:</strong> Text <strong>HELP</strong> for help or assistance with any issues related to these messages.</p></li>
                        </ul>
                        </section>

                        <br></br>
                        <section>
                        <h3>6. Privacy Policy</h3>
                        <p>For more information, please review our 
                        <Link
                            style={{ textDecoration: "underline", color: "#7b7b7b", paddingLeft: "3px" }}
                            to="/privacy-policy"
                            reloadDocument
                            className="menu--item"
                        >
                            Privacy Policy
                        </Link></p>
                        </section>
                      </div>

      
                      <div className="line--1"></div>
                    </div>
                    <div className="right--sq--box"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>

      {showSuccessPopup && (
        <div id="success--popup" className="success--container">
          <span id="close--modal" onClick={handleModalClose}>x</span>
          <div className="success--content">
            <p>Your request has been submited. One of our team members will be contacting you shortly.</p>
            <button onClick={handleRedirect} className="btn">Go to Home Page</button>
          </div>
        </div>
      )}

    </>
  );
}

export default IsLoadingHOC(TermsOfService);
